.signup-container {
  background: #fcfcfc;
  height: 100vh;
  position: relative;
  overflow-x: auto;
}
.signup-title {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-34);
  line-height: var(--line-height-130-percent);
  letter-spacing: var(--service-box-label-spacing);
  color: var(--sub-text-color);
}

.signup-sub-text {
  font-family: var(--roboto-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-150-percent);
  letter-spacing: -0.006em;
  color: rgba(10, 20, 27, 0.8);
}

.signup-sub-text span {
  color: #592c82;
  cursor: pointer;
}

.input-field {
  font-family: var(--roboto-regular);
  width: 100%;
  outline: none;
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-150-percent);
  letter-spacing: -0.006em;
  color: #a1acac;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0px 0px 12px;
  background: #f5f8fa;
  height: 50px;
  box-sizing: border-box;
  border-radius: 5px;
}

.btn-field {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-17);
  line-height: var(--line-height-26);
  align-items: center;

  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 8px;
}
.or-text-div {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #6a7575;
  line-height: var(--line-height-or-text);
  margin: 0px 0 0px;
}
.or-text {
  font-family: var(--roboto-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-17);
  color: #6a7575;
  background: #f5f8fa;
  padding: 0 8px;
}

.form-container {
  padding: 0px 16px 0px 16px;
}
.google-social-btn {
  padding: 12px 24px;
  position: static;
  background: #2d3748;
}

.linkedin-social-btn {
  background: #2867b2;
  border-radius: 8px;
}
.social-btn {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-17);
  line-height: var(--line-height-26);
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  align-items: center;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-top: 24px;
}

.signup-email-text {
  color: #0a141b;
  font-weight: bold;
}

.resend-email-text {
  font-weight: bold;
}

.verification-sub-text {
  font-family: var(--roboto-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-400);
  font-size: var(--font-size-14);
  line-height: var(--line-height-150-percent);
  letter-spacing: -0.006em;
  color: rgba(10, 20, 27, 0.8);
}

.resend-btn-field {
  font-family: var(--poppins-regular);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-500);
  font-size: var(--font-size-17);
  line-height: var(--line-height-26);
  align-items: center;
  color: #0a141b;
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 8px;
  border: 1px solid #0a141b;
  box-sizing: border-box;
  border-radius: 8px;
  background: #f5f8fa;
}
